<template>
    <td :class="tdClass" style="width: 8, 3%">
        {{ dispo }}
    </td>
</template>
<script>
export default {
    props: ["date", "dispos", "ficheId"],
    data: function() {
        return {
            tdClass: "",
            dispo: "",
        };
    },
    beforeMount() {
        this.dispos.forEach(dispo => {
            if (dispo.date == this.date && dispo.FicheId == this.ficheId) {
                this.dispo = dispo.description;
                if (dispo.dispo == "Complet") {
                    this.tdClass = "table-danger";
                } else if (dispo.dispo == "Disponible") {
                    this.tdClass = "table-success";
                }
            }
        });
    },
};
</script>
