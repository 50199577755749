<template>
    <div class="overflow-hidden p-0 cover">
        <br />

        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12">
                    <b-button variant="outline-success" @click="back"
                        >Retour</b-button
                    >
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-4">
                    <b-calendar
                        v-model="startPlage"
                        locale="fr-FR"
                        label-help=""
                        @context="onContext"
                    ></b-calendar>
                </div>
                <div class="col-sm-6">
                    <h3 v-if="dateArray.length == 0">
                        Séléctionner une date de début
                    </h3>
                    <h3 v-if="dateArray.length != 0">
                        Plage séléctionné: {{ dateArray[0].date }} au
                        {{ dateArray[6].date }}
                        <b-button @click="download">Télécharger</b-button>
                    </h3>
                </div>
            </div>
            <br />

            <div
                v-if="dateArray.length != 0"
                id="tables-download"
                class="col-sm-12"
            >
                <div v-for="t in types" :key="t.id" class="row saut">
                    <div v-if="fiches[t.id].length > 0" class="col-sm-12">
                        <br />
                        <br />
                        <br />
                        <table class="table table-bordered">
                            <thead class="thead thead-color">
                                <tr class="text-center">
                                    <td colspan="12">
                                        <h5>
                                            Disponibilité Locative -
                                            {{ t.label }}
                                        </h5>

                                        <h6>
                                            Du {{ dateArray[0].date }} au
                                            {{ dateArray[6].date }}
                                        </h6>
                                    </td>
                                </tr>
                                <tr>
                                    <th style="width: 8, 3%">Nom</th>
                                    <th style="width: 8, 3%">Adresse</th>
                                    <th style="width: 8, 3%">Téléphone</th>
                                    <th style="width: 8, 3%">Classement</th>
                                    <th style="width: 8, 3%">Tarifs</th>
                                    <th
                                        v-for="(date, index) in dateArray"
                                        :key="index"
                                        style="width: 8, 3%"
                                    >
                                        {{ date.day }} {{ date.date }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr
                                    v-for="fiche in fiches[t.id]"
                                    :key="fiche.id"
                                >
                                    <td style="width: 8, 3%">
                                        {{ fiche.name }}
                                    </td>
                                    <td style="width: 8, 3%">
                                        {{ fiche.adress }}
                                    </td>
                                    <td style="width: 8, 3%">
                                        {{ fiche.phone }}
                                    </td>
                                    <td style="width: 8, 3%">
                                        {{ fiche.ranking }}
                                    </td>
                                    <td style="width: 8, 3%">
                                        {{ fiche.prices }}
                                    </td>
                                    <tdComp
                                        v-for="(date, index) in dateArray"
                                        :key="index"
                                        :date="date.dateIso"
                                        :dispos="dispos[t.id]"
                                        :fiche-id="fiche.id"
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import tdComp from "@/components/common/dispo/component/TableTd.vue";
import html2pdf from "html2pdf.js";
export default {
    components: { tdComp },
    data: function() {
        return {
            menuModal: false,
            startPlage: "",
            endPlage: "",
            context: "",
            dispos: {},
            types: "",
            dateArray: "",
            fiches: {},
        };
    },
    watch: {
        context: async function() {
            this.endPlage = new Date(this.context.selectedYMD);
            this.endPlage.setDate(this.endPlage.getDate() + 6);

            this.endPlage = this.endPlage.toISOString();

            this.startPlage = this.startPlage + "T00:00:00Z";
            let ficheId = {};
            this.fiches = {};
            for (const type of this.types) {
                Vue.set(
                    this.dispos,
                    type.id,
                    await this.$store.dispatch("dispo/getDispoOfProject", {
                        projectId: 6,
                        typeId: type.id,
                        startDate: this.startPlage,
                        endDate: this.endPlage,
                    }),
                );
                for (const dispo of this.dispos[type.id]) {
                    let flag = false;
                    if (!ficheId[type.id]) {
                        ficheId[type.id] = [];
                    }

                    for (const typeId of ficheId[type.id]) {
                        if (typeId == dispo.Fiche.id) {
                            flag = true;
                        }
                    }
                    if (flag == false) {
                        ficheId[type.id].push(dispo.Fiche.id);
                    }
                }
                if (!this.fiches[type.id]) {
                    this.fiches[type.id] = [];
                }
                if (!ficheId[type.id]) {
                    ficheId[type.id] = [];
                }
                for (const fiche of ficheId[type.id]) {
                    this.fiches[type.id].push(
                        await this.$store.dispatch("dispo/getFiche", {
                            projectId: 6,
                            ficheId: fiche,
                        }),
                    );
                }
            }

            this.dateArray = [];
            let currentDate = new Date(this.context.selectedYMD);
            for (let i = 0; i < 7; i++) {
                this.dateArray.push({
                    dateIso: currentDate.toISOString(),
                    date:
                        currentDate.getDate() +
                        "/" +
                        (currentDate.getMonth() + 1),
                    day: currentDate.toLocaleDateString("fr-FR", {
                        weekday: "long",
                    }),
                });
                currentDate.setDate(currentDate.getDate() + 1);
            }
        },
    },

    async beforeMount() {
        this.types = await this.$store.dispatch("dispo/getAllTypes", {
            projectId: 6,
        });
    },
    methods: {
        onContext(ctx) {
            this.context = ctx;
        },
        download: function() {
            var element = document.getElementById("tables-download");

            html2pdf()
                .set({
                    pagebreak: { before: ".saut" },
                    filename: "Dispo.pdf",
                    margin: 0,
                })
                .from(element)
                .save();
        },
        back: function() {
            this.$router.push({
                name: "Dispo",
                params: {
                    projectId: 6,
                },
            });
        },
    },
};
</script>
<style scoped>
.thead-color {
    color: white;
    background-color: #444444;
}
</style>
